import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {initializeApp} from 'firebase/app';
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, updateProfile } from 'firebase/auth';
import {getFirestore, getDocs, query, where, collection, setDoc, doc} from 'firebase/firestore';


export const AuthContext = React.createContext();

const firebaseConfig = {
  apiKey: "AIzaSyCUBUyzsIoeetaGlQz07GVsiR536VW4ZwI",
  authDomain: "makemyguest.firebaseapp.com",
  projectId: "makemyguest",
  storageBucket: "makemyguest.appspot.com",
  messagingSenderId: "1070127926412",
  appId: "1:1070127926412:web:56eb542402309ea372bfa9",
  measurementId: "G-NE6J63QT27"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const AuthProvider = (props) => {
  let navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const signUp = async(data) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, data.email, data.password);
      const user = res.user;
      const colRef = collection(db, 'users');
      const q = query(colRef, where('uid', '==', user.uid));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
      const docRef = doc(db, 'users', user.uid);
      await setDoc(docRef, {
        username: data.displayName,
        email: data.email,
        photoURL: data.photoURL,
        phoneNumber: data.phoneNumber,
        dateofBirthday: data.dateofBirthday,
        agentGender: data.agentGender,
        preferredLanguage: data.preferredLanguage,
        address: data.address,
        authProvider: "email",
      });
      setUser(user);
      setLoggedIn(true);
      navigate('/account-settings');
    }else{
      signIn(user.email, user.uid);
    }
    }catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };




  const signIn = async(data) => {
    try {
      const res = await signInWithEmailAndPassword(auth, data.email, data.password);
      const user = res.user;
      setUser(user);
      setLoggedIn(true);
      navigate('/account-settings');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    }
  };

  const logOut = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      setLoggedIn(false);
      setUser({});
      navigate('/sign-in');
    }).catch((error) => {
      // An error happened.
      console.log(error);
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
        setUser({});
      }
    });
  }, []);
  

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
        updateProfile,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
